<template>
  <div>
    <div class="container-hint small">
      <span class="inline-block align-middle">公司篩選：</span>
      <el-checkbox-group
        v-model="compareCompanyFilter">
        <el-checkbox
          :label="item.value"
          :key="item.value"
          v-for="item in compareCompanyOptions">
          {{item.label}}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <!-- description -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-if="currentCompareItem=='description'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <CompanyDescriptionSection
            :title="company.label"
            :description="
              multiCompanyData[index].basic.multi
              && multiCompanyData[index].basic.multi.description
              && multiCompanyData[index].basic.multi.description[0]
              ? multiCompanyData[index].basic.multi.description[0].value
              : '-'
            ">
          </CompanyDescriptionSection>
        </div>
      </section>
    </div>
    <!-- basic -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-if="currentCompareItem=='basic'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <CompanyBasicTable
            :title="company.label"
            :basic="multiCompanyData[index].basic">
          </CompanyBasicTable>
        </div>
      </section>
    </div>
    <!-- shareholders -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='shareholders'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <ShareholdersTable
            :title="company.label"
            :shareholders="multiCompanyData[index].shareholders"
            :uniID="company.value"
            :stockType="getterMultiStockType[index]">
          </ShareholdersTable>
        </div>
      </section>
    </div>
    <!-- managers -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='managers'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <ManagersTable
            :title="company.label"
            :managers="multiCompanyData[index].managers"
            :uniID="company.value">
          </ManagersTable>
        </div>
      </section>
    </div>
    <!-- companys_history -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='companys_history'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <CompanysHistoryTable
            :title="company.label"
            :companys_history="multiCompanyData[index].companys_history"
            :uniID="company.value">
          </CompanysHistoryTable>
        </div>
      </section>
    </div>
    <!-- company_branchs -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='company_branchs'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <CompanyBranchsTable
            :title="company.label"
            :company_branchs="multiCompanyData[index].company_branchs"
            :uniID="company.value">
          </CompanyBranchsTable>
        </div>
      </section>
    </div>
    <!-- factorys -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='factorys'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <FactorysTable
            :title="company.label"
            :factorys="multiCompanyData[index].factorys"
            :uniID="company.value">
          </FactorysTable>
        </div>
      </section>
    </div>
    <!-- 登記地址近似公司 -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='登記地址近似公司'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <SimilarAddressTable
            :title="company.label"
            :登記地址近似公司="multiCompanyData[index].登記地址近似公司"
            :uniID="company.value">
          </SimilarAddressTable>
        </div>
      </section>
    </div>
    <!-- verdicts -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='verdicts'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <!-- <VerdictsTable
            :title="company.label"
            :verdicts="multiCompanyData[index].verdicts"
            :basic="multiCompanyData[index].basic"
            :uniID="company.value">
          </VerdictsTable> -->
          <VerdictsTable
            :title="company.label"
            :verdicts="multiCompanyData[index].verdicts"
            :basic="multiCompanyData[index].basic"
            :uniID="company.value">
          </VerdictsTable>
        </div>
      </section>
    </div>
    <!-- stock_relationship_chart -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='stock_relationship_chart'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <h4 class="section-title">
            {{company.label}}
            <el-button
              type="primary"
              size="mini"
              style="margin-left:10px"
              v-if="chartVersion==1"
              @click="handleChartVersionChange">
              體驗新版
            </el-button>
            <el-button
              plain
              size="mini"
              style="margin-left:10px"
              v-else-if="chartVersion==2"
              @click="handleChartVersionChange">
              恢復舊版
            </el-button>
          </h4>
          <!-- <CompanyStockRelationshipChart
            v-loading="multiIsStockLoading[index]"
            :uniID="company.value"
            :companyDataList="multiCompanyStockList[index] || []"
            :companyName="multiCompanyData[index].basic.company_name">
          </CompanyStockRelationshipChart> -->
          <CompanyStockRelationshipChart
            v-if="chartVersion==1"
            v-loading="multiIsStockFullLoading[company.value]"
            :cardId="`CompanyStockRelationshipChart_${index}`"
            :uniID="company.value"
            :sourceData="multiGoFull[company.value]"
            :companyName="company.label">
          </CompanyStockRelationshipChart>
          <CompanyStockTree2Chart
            v-else-if="chartVersion==2"
            v-loading="multiIsStockFullLoading[company.value]"
            :cardId="`CompanyStockTree2Chart_${index}`"
            :uniID="company.value"
            :chartData="multiGoFull[company.value]"
            :companyName="company.label">
          </CompanyStockTree2Chart>
        </div>
      </section>
    </div>
    <!-- stock_directed_chart -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='stock_directed_chart'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <h4 class="section-title">{{company.label}}</h4>
          <!-- <CompanyStockDirectedChart
            v-loading="multiIsStockLoading[index]"
            :uniID="company.value"
            :companyDataList="multiCompanyStockList[index] || []"
            :companyName="multiCompanyData[index].basic.company_name">
          </CompanyStockDirectedChart> -->
          <CompanyStockDirectedChart
            v-loading="multiIsStockOldLoading[index]"
            :cardId="`CompanyStockDirectedChart_${index}`"
            :uniID="company.value"
            :sourceData="multiGoOld[company.value]"
            :companyName="company.label">
          </CompanyStockDirectedChart>
        </div>
      </section>
    </div>
    <!-- news -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='news'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <NewsTable
            :title="company.label"
            :news="multiCompanyData[index].news"
            :uniID="company.value">
          </NewsTable>
        </div>
      </section>
    </div>
    <!-- disputes -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='disputes'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <DisputesTable
            :title="company.label"
            :disputes="multiCompanyData[index].disputes"
            :uniID="company.value">
          </DisputesTable>
        </div>
      </section>
    </div>
    <!-- procurement_bad_vendors -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='procurement_bad_vendors'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <ProcurementBadVendorsTable
            :title="company.label"
            :procurement_bad_vendors="multiCompanyData[index].procurement_bad_vendors"
            :uniID="company.value">
          </ProcurementBadVendorsTable>
        </div>
      </section>
    </div>
    <!-- trades -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='trades'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <TradesTable
            :title="company.label"
            :basic="multiCompanyData[index].basic"
            :trades="multiCompanyData[index].trades"
            :uniID="company.value">
          </TradesTable>
        </div>
      </section>
    </div>
    <!-- procurements -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='procurements'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <ProcurementsTable
            :title="company.label"
            :basic="multiCompanyData[index].basic"
            :procurements="multiCompanyData[index].procurements"
            :uniID="company.value">
          </ProcurementsTable>
        </div>
      </section>
    </div>
    <!-- patents -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='patents'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <PatentsTable
            :title="company.label"
            :patents="multiCompanyData[index].patents"
            :uniID="company.value">
          </PatentsTable>
        </div>
      </section>
    </div>
    <!-- awards -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='awards'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <AwardsTable
            :title="company.label"
            :awards="multiCompanyData[index].awards"
            :uniID="company.value">
          </AwardsTable>
        </div>
      </section>
    </div>
    <!-- publics_news -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='publics_news'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <PublicsNewsTable
            :title="company.label"
            :basic="multiCompanyData[index].basic"
            :publics_news="multiCompanyData[index].publics_news"
            :uniID="company.value">
          </PublicsNewsTable>
        </div>
      </section>
    </div>
    <!-- revenue_history -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='revenue_history'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <RevenueHistoryTable
            :title="company.label"
            :basic="multiCompanyData[index].basic"
            :revenue_history="multiCompanyData[index].revenue_history"
            :uniID="company.value">
          </RevenueHistoryTable>
        </div>
      </section>
    </div>
    <!-- ifrss_summary -->
    <div
      class="company-container"
      v-loading="isCompanyDataLoading"
      v-else-if="currentCompareItem=='ifrss_summary'">
      <section
        :key="company.value"
        v-for="(company,index) in compareCompanyOptions">
        <div v-show="compareCompanyFilter.includes(company.value)">
          <IfrssSummaryTable
            :title="company.label"
            :ifrss_summary="multiCompanyData[index].ifrss_summary"
            :uniID="company.value">
          </IfrssSummaryTable>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import * as apis from '@/apis/index.ts'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import getCompanyStockList from '@/utils/getCompanyStockList_legacy.js'
import CompanyDescriptionSection from '@/components/CompanyDescriptionSection/CompanyDescriptionSection.vue'
import CompanyBasicTable from '@/components/CompanyBasicTable/CompanyBasicTable.vue'
import ShareholdersTable from '@/components/ShareholdersTable/ShareholdersTable.vue'
import ManagersTable from '@/components/ManagersTable/ManagersTable.vue'
import CompanysHistoryTable from '@/components/CompanysHistoryTable/CompanysHistoryTable.vue'
import CompanyBranchsTable from '@/components/CompanyBranchsTable/CompanyBranchsTable.vue'
import FactorysTable from '@/components/FactorysTable/FactorysTable.vue'
import SimilarAddressTable from '@/components/SimilarAddressTable/SimilarAddressTable.vue'
// import VerdictsTable from '@/components/VerdictsTable/VerdictsTable.vue'
import VerdictsTable from '@/components/VerdictsTable/VerdictsTable.vue'
// import CompanyStockRelationshipChart from '@/components/CompanyStockRelationshipChart_legacy/CompanyStockRelationshipChart.vue'
// import CompanyStockDirectedChart from '@/components/CompanyStockDirectedChart_legacy/CompanyStockDirectedChart.vue'
import CompanyStockRelationshipChart from '@/components/charts/businessNetwork/CompanyStockRelationshipChart.vue'
import CompanyStockTree2Chart from '@/components/charts/businessNetwork/CompanyStockTree2Chart.vue'
import CompanyStockDirectedChart from '@/components/charts/businessNetwork/CompanyStockDirectedChart.vue'
import NewsTable from '@/components/NewsTable/NewsTable.vue'
import DisputesTable from '@/components/DisputesTable/DisputesTable.vue'
import ProcurementBadVendorsTable from '@/components/ProcurementBadVendorsTable/ProcurementBadVendorsTable.vue'
import TradesTable from '@/components/TradesTable/TradesTable.vue'
import ProcurementsTable from '@/components/ProcurementsTable/ProcurementsTable.vue'
import PatentsTable from '@/components/PatentsTable/PatentsTable.vue'
import AwardsTable from '@/components/AwardsTable/AwardsTable.vue'
import PublicsNewsTable from '@/components/PublicsNewsTable/PublicsNewsTable.vue'
import RevenueHistoryTable from '@/components/RevenueHistoryTable/RevenueHistoryTable.vue'
import IfrssSummaryTable from '@/components/IfrssSummaryTable/IfrssSummaryTable.vue'
import toOldFormatV4 from '@/utils/to_old_format_v4.js'
import toOldFormat from '@/utils/to_old_format.js'

export default {
  props: ['menuIndex'],
  components: {
    CompanyDescriptionSection,
    CompanyBasicTable,
    ShareholdersTable,
    ManagersTable,
    CompanysHistoryTable,
    CompanyBranchsTable,
    FactorysTable,
    SimilarAddressTable,
    // VerdictsTable,
    VerdictsTable,
    CompanyStockRelationshipChart,
    CompanyStockTree2Chart,
    CompanyStockDirectedChart,
    NewsTable,
    DisputesTable,
    ProcurementBadVendorsTable,
    TradesTable,
    ProcurementsTable,
    PatentsTable,
    AwardsTable,
    PublicsNewsTable,
    RevenueHistoryTable,
    IfrssSummaryTable
  },
  data () {
    return {
      compareCompanyFilter: [],
      // isCompanyDataLoading: true
      multiIsStockFullLoading: {}, // { uniID: { nodes: [], edges: [] } }
      multiIsStockOldLoading: {}, // { uniID: { nodes: [], edges: [] } }
      // multiCompanyStockList: [] // 關係人地圖資料（圖表上所有節點的公司股權資料）
      chartVersion: 1
    }
  },
  computed: {
    ...mapState('multiCompanyDetail', [
      'uniIDList',
      'getList',
      'multiCompanyData',
      'multiCompanyNews',
      'isCompanyDataLoading',
      'isCompanyNewsLoading',
      'multiGoFull',
      'multiGoOld',
    ]),
    ...mapGetters('multiCompanyDetail', [
      'getterMultiStockType'
    ]),
    // 目前的比較項目
    currentCompareItem () {
      return this.menuIndex
    },
    compareCompanyOptions () {
      return this.multiCompanyData.map(d => {
        return {
          label: d.basic.company_name,
          value: d.basic.uniID
        }
      })
    },
  },
  methods: {
    ...mapMutations('multiCompanyDetail', [
      'mutationMultiGoFull',
      'mutationMultiGoOld'
    ]),
    handleChartVersionChange () {
      if (this.chartVersion == 1) {
        this.chartVersion = 2
      } else {
        this.chartVersion = 1
      }
      relationshipChartVersionStorage.setVersion(this.chartVersion)
    },
    fetchAllGraph () {
      const fetchGraph = (uniID) => {
        this.multiIsStockFullLoading[uniID] = true
        this.multiIsStockOldLoading[uniID] = true

        const fullPromise = apis.graphForDbs({
          uniid: uniID,
          skip_gov_neibor: '1',
          skip_independent_director_neibor: '1',
          only_in_bussiness: false
        })
        .then(data => {
          this.multiIsStockFullLoading[uniID] = false

          const parsedData = toOldFormatV4(data)
          console.log('toOldFormatV4 - full', parsedData)
          this.mutationMultiGoFull({
            uniID: uniID,
            payload: {
              nodes: parsedData.d3.nodes,
              edges: parsedData.d3.edges
            }
          })
          return true
        })
        .catch(d => {
          this.multiIsStockFullLoading[uniID] = false
          return Promise.reject(d)
        })
        const upPromise = apis.graphForDbsOld({
          uniid: uniID,
          "mode": "up",
          "format": "json",
          "mini": "0",
          "scope": "classic",
          "force_split": "1",
          only_in_bussiness: false
        })
        .then(data => {
          this.multiIsStockOldLoading[uniID] = false

          const parsedData = toOldFormat(data)
          console.log('toOldFormat - up', parsedData)
          this.mutationMultiGoOld({
            uniID: uniID,
            payload: {
              nodes: parsedData.d3.nodes,
              edges: parsedData.d3.edges
            }
          })
          return true
        })
        .catch(d => {
          this.multiIsStockOldLoading[uniID] = false
          return Promise.reject(d)
        })
      }

      this.compareCompanyFilter.forEach(d => {
        fetchGraph(d)
      })     
    }
    // returnCompanyData (uniID) {
    //   return this.multiCompanyData.find(d => d.basic.uniID == uniID)
    // }
    // 取得關係人地圖圖表所需資料
    // async loadCompanyStockList (currentUniID, index) {
    //   let loadedNodeUniIDs = [] // 紀錄已取得的公司uniid

    //   const getData = async (uniID) => {
    //     const searchQuery = {
    //       "uniID": uniID,
    //       "get": {
    //         "stock": {}
    //       }
    //     }
    //     return apis.companyGet(searchQuery)
    //   }
    //   const addDataList = async (dataList, uniIDs) => {
    //     // console.log(dataList)
    //     let promiseList = []
    //     // 取得資料
    //     for (const uniID of uniIDs) {
    //       const promise = getData(uniID)
    //       promiseList.push(promise)
    //     }
    //     this.$set(this.multiIsStockLoading, index, true)
    //     let resultList = await Promise.all(promiseList)
    //     this.$set(this.multiIsStockLoading, index, false)
    //     // 過濾重覆資料
    //     resultList = resultList
    //       .filter(d => {
    //         if (!d.payload || !d.payload.basic || !d.payload.basic.uniID) {
    //           return false
    //         }
    //         if (loadedNodeUniIDs.find(uniID => uniID === d.payload.basic.uniID)) {
    //           return false
    //         }
    //         return true
    //       })
    //       .map(d => d.payload)
    //     if (!resultList.length) {
    //       return dataList
    //     }
    //     // 紀錄已取得的公司uniid
    //     const newLoadedNodeUniIDs = resultList.map(d => d.basic.uniID)
    //     loadedNodeUniIDs = loadedNodeUniIDs.concat(newLoadedNodeUniIDs)
    //     // 合併資料陣列
    //     dataList = dataList.concat(resultList)
    //     // 取得要繼續查詢的上游資料
    //     let topUniIDs = []
    //     for (const payload of resultList) {
    //       if (!payload.stock || !payload.stock.top) {
    //         continue
    //       }
    //       for (const d in payload.stock.top) {
    //         const top = payload.stock.top[d]
    //         if (!top.uniID) {
    //           continue
    //         }
    //         if (topUniIDs.find(uniID => uniID === top.uniID)) {
    //           continue
    //         }
    //         if (loadedNodeUniIDs.find(uniID => uniID === top.uniID)) {
    //           continue
    //         }
    //         topUniIDs.push(top.uniID)
    //       }
    //     }
    //     if (!topUniIDs.length) {
    //       return dataList
    //     }
    //     // 遞迴
    //     dataList = await addDataList(dataList, topUniIDs)
    //     // 回傳
    //     return dataList
    //   }
    //   const result = await addDataList([], [currentUniID])
    //   this.$set(this.multiCompanyStockList, index, result)
    // }
  },
  created () {
  },
  watch: {
    'compareCompanyOptions': {
      handler (d) {
        this.compareCompanyFilter = d.map(d => d.value)

        if (this.currentCompareItem === 'stock_relationship_chart' || this.currentCompareItem === 'stock_directed_chart') {
          this.fetchAllGraph()
        }
      },
      immediate: true
    },
    'currentCompareItem': {
      handler (d) {
        // 切換到「關係人地圖」頁籤，並且資料未取得
        // if ((d === 'stock_relationship_chart' || d === 'stock_directed_chart')
        // && this.multiCompanyStockList.every(stock => !stock.length)) {
        //   // this.compareCompanyFilter.forEach((d, i) => {
        //   //   this.loadCompanyStockList(d, i)
        //   // })
        //   try {
        //     this.multiIsStockLoading = this.compareCompanyFilter.map(() => false)
        //     const promiseList = this.compareCompanyFilter.map(d => 
        //       new Promise(resolve => {
        //         resolve(getCompanyStockList(d))
        //       })
        //     )
        //     promiseList.forEach((promise, index) => {
        //       promise.then(result => {
        //         this.multiIsStockLoading[index] = false
        //         this.$set(this.multiCompanyStockList, index, result)
        //       })
        //     })
        //   } catch (e) {
        //     console.log(e)
        //   }
        // }

        

        if (d === 'stock_relationship_chart' || d === 'stock_directed_chart') {
          // this.actionMultiCompanyGraphForDbs(this.currentUniID)
          this.fetchAllGraph()

        }
      },
      immediate: true
    }
  }
}
</script>
